/* CSS reset */

* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
  text-decoration: none;
  border: none;
}

a {
  color: inherit;
  cursor: pointer;
}

body {
  background-color: #ecf0f1;
}