@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap);
/* CSS reset */

* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
  text-decoration: none;
  border: none;
}

a {
  color: inherit;
  cursor: pointer;
}

body {
  background-color: #ecf0f1;
}
body {
  font-family: 'Roboto Slab', serif;
}

/* p, span, h1, h2, h3, h4, h5, ul, li, button {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
} */
